import Loading from '@components/Loading';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import CookieConsent from "react-cookie-consent";
import { useSession } from 'next-auth/react';
import { Link } from '@chakra-ui/react'

export default function Layout({ children }: { children: any }) {
  const { data: _, status } = useSession();

  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  }

  useEffect(()=>{
    setLoading(status === "loading"? true : false);
  }, [status]);

  useEffect(() => {

    const handleStart = (url: string) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);
  }, [router]);

  return (
    <>
      {loading ?
        <Loading loading={loading} /> :
        (
          <motion.main
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ delay: 0.5, duration: 0.5, ease: [0.17, 0.67, 0.83, 0.67] }}
          >
            {children}
            <CookieConsent
              location="bottom"
              buttonText="Aceptar"
              expires={150}
              buttonStyle={{ fontSize: "14px", borderRadius: "20px" }}

            >
              Esto sitio web utiliza cookies para mejorar la experiencia del usuario.{" "}
              <Link href="/terms"  style={{ fontSize: "12px", fontStyle: "italic" }} isExternal>Lea nuestra política de privacidad</Link>
            </CookieConsent>
          </motion.main>
        )
      }
    </>
  );
}
