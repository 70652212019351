import {  Flex, Spinner, VStack, Text } from '@chakra-ui/react';
import React from 'react';

export default function Loading(props: { loading: any }) {
  return props.loading ? (
    <Flex width={"100vw"} height={"100vh"}  justifyContent="center" alignItems="center">
      <VStack>
        <Spinner size='xl' speed='0.65s' />
        <Text fontSize="sm" >cargando</Text>
      </VStack>
    </Flex>
  ) : <></>
}
