import { useEffect, useState } from 'react';
import {
  Text,
  Image,
  Box,
  Flex,
  Container,
  Center,
  Hide,
  VStack,
  Heading,
  Button,
  Show,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Game, GameDetailType, GameStatus } from '@interfaces/Game';
import { useToast } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import logger from '@logger/logger';
import { MdLockOutline, MdChevronRight, MdLogin } from 'react-icons/md';
import { useStages } from '@hooks/useStages';
import WinnerPrizes from '@components/WinnerPrizes';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

export default HomeModule;

function HomeModule() {
  const toast = useToast();
  const router = useRouter();
  const { stages, loadNewStages } = useStages();
  const [loading, setLoading] = useState<boolean>(true);
  const { data: session, status } = useSession();
  const localStorageKey = `06511df3-gbbf_get_stages`;

  function convertDateToUTC(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  const handlerMinutesGetStages = () => {
    if (typeof window === 'undefined') return;
    let logicPrizesStr = window.localStorage.getItem(localStorageKey);
    let sendRequest = false;
    let today = new Date();
    let nowTimestamp = convertDateToUTC(today).valueOf();
    if (logicPrizesStr != null) {
      let logicPrizes = JSON.parse(logicPrizesStr);
      if (logicPrizes.dt_timestamp_expires < nowTimestamp) {
        sendRequest = true;
      }
    }

    if (sendRequest || logicPrizesStr == null) {
      loadStages();
      const tomorrowMidnight = new Date(today);
      tomorrowMidnight.setDate(tomorrowMidnight.getDate() + 1);
      tomorrowMidnight.setHours(0);
      tomorrowMidnight.setMinutes(1);
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          dt_timestamp_expires: convertDateToUTC(tomorrowMidnight).valueOf(),
        })
      );
    }
  };

  function loadStages() {
    logger.info('loadStates called');
    loadNewStages().then(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    const idInterval = setInterval(() => {
      handlerMinutesGetStages();
    }, 1 * 60 * 1000);
    return () => {
      clearInterval(idInterval);
    };
  }, []);

  useEffect(() => {
    if (status !== 'loading' && !stages) {
      setLoading(true);
      loadStages();
    }
  }, [status, stages]);

  function renderActionButton(id_stage: number, game: Game) {
    // const stage = getQuizStage();
    if (!id_stage || !game) {
      return (
        <Button
          rightIcon={<MdLockOutline />}
          colorScheme="orange"
          isLoading={loading}
          loadingText="Cargando"
          spinnerPlacement="start"
          onClick={() =>
            toast({
              title: 'Jogo bloqueado.',
              description:
                '¡No se puede jugar, espera noticias para volver a jugar!',
              status: 'warning',
              duration: 9000,
              isClosable: true,
            })
          }
        >
          Bloqueado
        </Button>
      );
    }
    // game.
    // let game = stage?.games[0];
    switch (Number(game.status)) {
      case GameStatus.Unlocked:
        return (
          <Button
            rightIcon={<MdChevronRight color="#14284B" fontSize={'18px'} />}
            bgColor="#FFBB38"
            fontFamily={'Inter'}
            fontWeight={500}
            textAlign="center"
            justifyContent="center"
            onClick={() => {
              router.push(
                `/game?id_game=${game.idGame}&id_stage=${id_stage}&type=${game.type}`
              );
            }}
          >
            ¡Jugar ahora!
          </Button>
        );
      case GameStatus.Finished:
        return (
          <Button
            rightIcon={<MdLockOutline />}
            colorScheme="orange"
            isLoading={loading}
            loadingText="Cargando"
            spinnerPlacement="start"
            onClick={() =>
              toast({
                title: 'Jogo bloqueado.',
                description:
                  '¡No se puede jugar, espera noticias para volver a jugar!',
                status: 'warning',
                duration: 9000,
                isClosable: true,
              })
            }
          >
            Jogo expirado nessa fase
          </Button>
        );
      case GameStatus.Played:
        return (
          <>
            <Button
              rightIcon={<MdLockOutline />}
              bgColor="#F14971"
              color="white"
              fontWeight={400}
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: '¡Esperas hasta mañana!',
                  description:
                    'Vuelve mañana para jugar más. O accede a tu perfil para más informaciones. ',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
             Sin jugada disponible
            </Button>
            <Text
              color="white"
              fontSize={['md', 'md', 'md', 'lg']}
              align="center"
              px={[0, 10, 10]}
            >
              ¡Vuelve mañana para jugar más!
            </Text>
          </>
        );
      case GameStatus.Locked:
      default:
        if (!session) {
          return (
            <Button
              leftIcon={<MdLogin />}
              colorScheme="orange"
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              onClick={() => {
                router.push(
                  `/game?id_game=${game.idGame}&id_stage=${id_stage}&type=${game.type}`
                );
              }}
            >
             ¡Jugar ahora!
            </Button>
          );
        } else {
          return (
            <Button
              rightIcon={<MdLockOutline />}
              colorScheme="orange"
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: 'Jogo bloqueado.',
                  description:
                    '¡No se puede jugar, espera noticias para volver a jugar!',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
              Bloqueado
            </Button>
          );
        }
    }
  }

  const renderHeaderDetails = () => {
    const Title = () => (
      <Heading
        color="white"
        fontSize={{ base: '20px', md: '30px', lg: '38px' }}
        as="em"
      >
        Máquina de Promos: ¡la solución de <span style={{ color: '#00FF23' }}>Promotion as a Service</span> más completa del mundo!
      </Heading>
    );

    return (
      <>
        <Title />
        <Text
          color="white"
          fontSize={{ base: '14px', md: '18px', lg: '22px' }}
          opacity={0.8}
        >
          ¡Diferentes herramientas de marketing para aumentar las ventas, generar más clientes potenciales, conocer mejor a tus clientes y mucho más! 
        </Text>

        <Box
          as={motion.div}
          dragConstraints={{ left: -100, right: 100 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition="0.05s linear"
        >
          <Center flexDir={'column'}>
            <Button
              borderWidth="3px"
              p={8}
              pl={10}
              pr={10}
              borderColor="#00FF23"
              _hover={{ bg: 'orange.500' }}
              _active={{ bg: 'orange.500' }}
              borderRadius={30}
              variant="outline"
              onClick={() => {
                router.push('/login');
              }}
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              size="lg"
            >
              <Text color="white">
              ¡REGÍSTRATE Y
                <br />DESCÚBRELO!
              </Text>
            </Button>
          </Center>
        </Box>
      </>
    );
  };
  const renderHeaderBanner = () => {
    return (
      <Box width={{sm:'80%',  md: '90%' }} mr={[0, 0, 5]} mb={5}>
        <Image src={'/imgs/banne.png'} />
      </Box>
    );
  };

  // if(loading){
  //   return <Loading loading={loading} />
  // }

  return (
    <>
      <Box color="white"  overflow="auto">
        <Flex w="full" h="full">
          {/* {loading ? (
            <Center my={[10, 10, 20]} minH="440px">
              <Spinner size="xl" speed="0.65s" alignSelf={'center'} />
            </Center>
          ) : ( */}
          <Container
            maxW={{ base: 'full', md: '9xl', lg: '8xl' }}
            // p={[5, 5, 5, 5]}
            py={[2, 2, 2, 2]}
            textAlign="center"
          >
            <Flex flexDir={['column', 'column', 'row']} align="center">
              {renderHeaderBanner()}
              <VStack
                spacing={[0, 5, 5, 5]}
                direction={['row', 'row']}
                justify="center"
                w="full"
                textAlign="start"
              >
                {renderHeaderDetails()}
              </VStack>
            </Flex>
            {/* </SimpleGrid> */}
          </Container>
          {/* )} */}
        </Flex>
      </Box>
      <Box py="5">{/*do not remove */}</Box>
      <Box bgColor="white" w="full" py="2">
        <Text textAlign="center" fontSize={['20px', '25px']} px={['2', '5']}>
          <b>Atención: ¡esta promoción no es real!</b> Los premios y sorteos son solo simulaciones para presentar 
          las funciones de la solución de la {' '}
          <b>Máquina de Promos.</b>
        </Text>
      </Box>
      <Box py={'5'}>{/*do not remove */}</Box>
      {stages?.map((stage) => (
        <Box key={`stage_${stage.id}`} marginX={[2, 5, 5]} borderTopRadius={20}>
          <Box
            borderTopRadius={20}
            padding={5}
            background={stage.card_header_style.bg_color}
            color={stage.card_header_style.font_color}
          >
            <HStack>
              <Image
                color="white"
                src={'/icons/flame.svg'}
                height={5}
                width={5}
              />
              <Text fontWeight={700} fontFamily="DM Sans">
                {stage.name}
              </Text>
            </HStack>
          </Box>
          <VStack bgColor="white">
            <HStack>
              <IconButton
                aria-label="Esquerda"
                icon={<BsArrowLeft />}
                color="#747474"
                variant="ghost"
                fontSize="25px"
                onClick={() => {
                  var banner = document.getElementById(`banner_stage_${stage.id}`)
                  if(banner != undefined && banner != null){
                    banner.scrollLeft -= 20
                  }
                  // putNotificationViewedReq("all")
                  // setShowModal(false)
                }}
              />

              <IconButton
                aria-label="Direita"
                icon={<BsArrowRight />}
                color="#747474"
                variant="ghost"
                fontSize="25px"
                onClick={() => {
                  var banner = document.getElementById(`banner_stage_${stage.id}`)
                  if(banner != undefined && banner != null){
                    banner.scrollLeft += 20
                  }
                  // putNotificationViewedReq("all")
                  // setShowModal(false)
                }}
              />
            </HStack>
          </VStack>
          <HStack bgColor="white" overflowX={'auto'} id={`banner_stage_${stage.id}`}>
            {stage.games
              .sort((a, b) => a.status - b.status)
              .map((game) => (
                <Box
                  as={motion.div}
                  key={`game_${game.idGame}`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition="1s linear"
                  viewport={{ once: true }}
                >
                  <Center>
                    <Container
                      background={game.card_style.bg_color}
                      color={game.card_style.font_color}
                      w={'300px'}
                      m={5}
                      borderRadius={10}
                      p={5}
                    >
                      <Box textAlign="center" >
                        <VStack spacing={5} align="start">
                          <Image
                            src={
                              game.type == GameDetailType.Quiz
                                ? '/icons/game-quiz.svg'
                                : game.type == GameDetailType.Live
                                ? '/icons/game-live.svg'
                                : game.type == GameDetailType.Mission
                                ? '/icons/game-missao.svg'
                                : game.type == GameDetailType.Keyword
                                ? '/icons/game-palavra.svg'
                                : '/icons/game-quiz-bolao.svg'
                            }
                            height={10}
                            width={10}
                          />
                          <Text
                            color="white"
                            fontSize={{ base: '20px', md: '20px' }}
                            fontWeight={700}
                          >
                            {game.name}
                          </Text>
                          <Text
                            overflow="auto"
                            textAlign="start"
                            fontWeight={400}
                            pb="15px"
                            h="68px"
                            dangerouslySetInnerHTML={
                              game ? { __html: game?.description } : undefined
                            }
                          ></Text>
                          {/* <Text fontSize="2xl" color="white" fontWeight={400}>
                        {game.description}
                      </Text> */}
                          {renderActionButton(stage.id, game)}
                        </VStack>
                      </Box>
                    </Container>
                  </Center>
                </Box>
              ))}
          </HStack>
        </Box>
      ))}
      <Box py="5">{/*do not remove */}</Box>
      <Box
        background="linear-gradient(180deg, #BFD0F0 0%, #0355F2 99.37%);"
        // py={5}
      >
        <Show above="md">
          <Show above="lg">
            <Image src="/imgs/prizes.png" width="100%" />
          </Show>
          <Hide above="lg">
            <Image src="/imgs/prizes-tablets.png" width="100%" />
          </Hide>
        </Show>
        <Show below="md">
          <Image src="/imgs/prizes-smartphones.png" width="100%" />
        </Show>
      </Box>
      <Box py="5">{/*do not remove */}</Box>
      <WinnerPrizes />
    </>
  );
}
